import React, { Component } from "react"


import IronCAPBountyContest from "../../components/sections/bountyContest/ironCAPBountyContest"

class IronCAPBountyContestEn extends Component {
  constructor(props) {
    super(props)

    this.state = {
       lang:"en"
    }
      }

  render() {
  	
    return (
     
		<IronCAPBountyContest 
		lang={this.state.lang}
		/>
     
    )
  }
}

export default IronCAPBountyContestEn
